import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AuthActions } from "reducers/models";

const EditTableRow = () => {
  const dispatch = useDispatch();
  const hasPopUp = useSelector((s) => s.common.hasPopUp);
  const [editedData, setData] = useState({});
  return (
    <div style={{ minWidth: "500px" }}>
      <div className="table">
        <div className="table--header">
          {(hasPopUp.data?.headers || []).map((h) => {
            return <div key={h.name}>{h.name}</div>;
          })}
        </div>
        <div className="table--row">
          {(hasPopUp.data?.headers || []).map((h) => {
            return (
              <div key={h.name}>
                <input
                  type="text"
                  value={editedData[h.name] || hasPopUp.data[h.name]}
                  onChange={(e) => {
                    if (e.target.value)
                      setData({
                        ...editedData,
                        [h.name]: e.target.value,
                      });
                  }}
                />
              </div>
            );
          })}
        </div>
        <div className="table--actions">
          <button
            onClick={() => {
              dispatch(
                AuthActions.updateTableRow({ ...editedData, id: hasPopUp.data.id, table_id: hasPopUp.data.table_id }),
              );
            }}
          >
            <i className="fas fa-save"></i> Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditTableRow;
