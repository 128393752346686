import { createActions, createReducer } from "reduxsauce";

const { Types, Creators } = createActions({
  getMain: ["access_token"],
  createCategory: ["data"],
  createCategorySuccess: ["categories"],
  getCategories: ["data"],
  setCategories: ["data"],
  deleteCatgory: ["data"],
  setAuth: ["auth"],
  getUsers: ["data"],
  setUsers: ["users"],
  createTable: ["data"],
  editTable: ["data"],
  getTables: ["data"],
  setTables: ["tables"],
  deleteTable: ["data"],
  uploadLead: ["data", "cb"],
  getTableRows: ["params"],
  setTableRows: ["tableDatas"],
  deleteRowTable: ["data"],
  updateTableRow: ["data"],
  logOut: [""],
  getTableModels: [""],
  setTableModels: ["tableModels"],
  assignTable: ["data"],
  transferRows: ["data"],
  //
  getRoles: [""],
  setRoles: ["roles"],
  updateRolePermission: ["id", "array", "tipo"],
  createRole: ["data"],

  createUser: ["data"],
  editUser: ["data"],
  deleteUser: ["data"],

  editCategory: ["data"],
  //
  getViciDialLists: [""],
  setViciDialLists: ["viciDialLists"],
});

export const AuthTypes = Types;
export default Creators;

var url = new URL(window.location.href);
const urlToken = url.searchParams.get("access_token");
const storageAuth = JSON.parse(localStorage.getItem("auth") || "{}");

const INITIAL_STATE = {
  viciDialLists: [],
  roles: [],
  tableModels: [],
  auth: urlToken ? { access_token: urlToken } : storageAuth,
  users: [],
  loading: false,
  error: null,
  lastAccess: [],
  password: [],
  categories: [],
  data: [],
  tables: [],
  tableDatas: {},
};

export const reducer = createReducer(INITIAL_STATE, {
  SET_VICI_DIAL_LISTS: (state, { viciDialLists }) => ({
    ...state,
    viciDialLists,
  }),

  SET_ROLES: (state, { roles }) => ({
    ...state,
    roles,
  }),
  //
  SET_TABLE_MODELS: (state, { tableModels }) => ({
    ...state,
    tableModels,
  }),
  SET_TABLE_ROWS: (state, { tableDatas }) => ({
    ...state,
    tableDatas,
  }),
  SET_TABLES: (state, { tables }) => ({
    ...state,
    tables,
  }),
  SET_USERS: (state, { users }) => ({
    ...state,
    users,
  }),
  SET_AUTH: (state, { auth }) => ({
    ...state,
    auth,
  }),
  CREATE_CATEGORY_SUCCESS: (state, { categories }) => {
    return {
      ...state,
      categories: [...state.categories, categories],
    };
  },
  GET_CATEGORIES: (state) => ({
    ...state,
    data: [],
  }),
  SET_CATEGORIES: (state, { data }) => ({
    ...state,
    categories: data,
  }),
});
