import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Select } from "antd";
import { AuthActions } from "reducers/models";

const initialTableData = {
  count: 1,
  fields: {},
};

const CreateTable = ({ createTable, editTable, currentCategory, currentTableData = {}, toEdit }) => {
  const [tableData, setData] = useState(initialTableData);
  useEffect(() => {
    if (currentTableData.id && !tableData["table_name"]) {
      setData({
        ...tableData,
        table_name: currentTableData.name,
        fields: JSON.parse(currentTableData.detail).reduce((a, b, c) => ({ ...a, [c]: { ...b, newCol: false } }), {}),
        count: JSON.parse(currentTableData.detail).length,
        table_id: currentTableData.id,
      });
    }
  }, [currentTableData]);
  const oldCols = JSON.parse(currentTableData.detail || "[]").reduce((a, b, c) => ({ ...a, [c]: b }), {});

  return (
    <div className="createTable">
      <div className="createTable-name">
        <input
          disabled={toEdit}
          type="text"
          placeholder="Table name"
          value={(tableData["table_name"] || "").split("db_file_")[1]}
          onChange={(e) => {
            setData({
              ...tableData,
              table_name: e.target.value,
            });
          }}
        />
        <button
          onClick={() => {
            if (toEdit) {
              editTable({
                ...tableData,
                category_id: currentCategory.id,
                fields: Object.values(tableData.fields),
              });
            } else {
              createTable({
                ...tableData,
                category_id: currentCategory.id,
                fields: Object.values(tableData.fields),
              });
            }
          }}
        >
          Save
        </button>
      </div>
      <div className="createTable-cols">
        {new Array(tableData.count).fill(" ").map((i, a) => {
          return (
            //  !oldCols.includes(tableData["fields"]?.[a]?.name) &&
            <div key={a} className="createTable-col">
              <div className="createTable-col_name">
                <input
                  type="text"
                  placeholder="Column name"
                  value={tableData["fields"]?.[a]?.name || ""}
                  onChange={(e) => {
                    setData({
                      ...tableData,
                      fields: {
                        ...tableData.fields,
                        [a]: {
                          ...tableData.fields?.[a],
                          name: e.target.value.replaceAll(" ", "_"),
                          ...(toEdit ? { old: oldCols?.[a]?.name, new: e.target.value.replaceAll(" ", "_") } : {}),
                        },
                      },
                    });
                  }}
                />
              </div>
              <div className="createTable-col_type">
                <Select
                  placeholder="Column type"
                  value={tableData["fields"]?.[a]?.type}
                  onChange={(e) => {
                    setData({
                      ...tableData,
                      fields: {
                        ...tableData.fields,
                        [a]: {
                          ...tableData.fields?.[a],
                          type: e,
                        },
                      },
                    });
                  }}
                >
                  <Select.Option value="string"> String </Select.Option>
                  <Select.Option value="biginteger"> Number </Select.Option>
                  <Select.Option value="text"> Text </Select.Option>
                  <Select.Option value="boolean"> Boolean </Select.Option>
                  <Select.Option value="date"> Date </Select.Option>
                  <Select.Option value="float"> Float </Select.Option>
                </Select>
              </div>
              <div className="createTable-col_null">
                <Select
                  value={tableData["fields"]?.[a]?.nullable}
                  placeholder="Column nullable?"
                  onChange={(e) => {
                    setData({
                      ...tableData,
                      fields: {
                        ...tableData.fields,
                        [a]: {
                          ...tableData.fields?.[a],
                          nullable: e,
                        },
                      },
                    });
                  }}
                >
                  <Select.Option value={true}> Yes </Select.Option>
                  <Select.Option value={false}> No </Select.Option>
                </Select>
              </div>
              <div className="createTable-col_uniq">
                <Select
                  placeholder="Column unique?"
                  value={!tableData["fields"]?.[a]?.unique ? false : true}
                  onChange={(e) => {
                    setData({
                      ...tableData,
                      fields: {
                        ...tableData.fields,
                        [a]: {
                          ...tableData.fields?.[a],
                          unique: e,
                        },
                      },
                    });
                  }}
                >
                  <Select.Option value={true}> Yes </Select.Option>
                  <Select.Option value={false}> No </Select.Option>
                </Select>
              </div>
            </div>
          );
        })}

        <div
          className="createTable-add"
          onClick={() => {
            setData({
              ...tableData,
              count: tableData.count + 1,
              fields: {
                ...tableData.fields,
                [tableData.count]: {
                  newCol: true,
                  name: "",
                },
              },
            });
          }}
        >
          <i className="fal fa-plus" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  );
};
export default connect(null, { ...AuthActions })(CreateTable);
