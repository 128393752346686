import React from "react";
import { connect } from "react-redux";
import { CommonActions } from "reducers/models";

const FilterTable = ({ data, getTableRows, filterData, setFilterData }) => {
  const columns = JSON.parse(data.table?.detail || "[]");

  return (
    <>
      <div className="filterTable">
        <div className="header">Filter table</div>
        {Array.isArray(columns || []) &&
          (columns || []).map((col) => {
            return (
              <div key={col.name}>
                <div className="label">{col.name}</div>
                <input
                  value={filterData?.[col.name]}
                  onChange={(e) => {
                    setFilterData({ ...filterData, [col.name]: e.target.value });
                  }}
                  type="text"
                  className="ant-input"
                />
              </div>
            );
          })}
      </div>
      <button
        onClick={() => {
          getTableRows({
            ...filterData,
            table_id: data.table.id,
          });
        }}
      >
        Submit
      </button>{" "}
    </>
  );
};

const mstp = ({ common: { filterData } }) => ({ filterData });
export default connect(mstp, CommonActions)(FilterTable);
