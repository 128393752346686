import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Input } from "antd";
import { AuthActions } from "reducers/models";
const EditUser = ({ data: { role_id, user } }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ role_id, ...user });
  return (
    <>
      <div>
        <div className="label">First Name</div>
        <Input
          value={formData["first_name"]}
          onChange={(e) => {
            setFormData({ ...formData, first_name: e.target.value });
          }}
          type="text"
        />
      </div>
      <div>
        <div className="label">Last Name</div>

        <Input
          value={formData["last_name"]}
          onChange={(e) => {
            setFormData({ ...formData, last_name: e.target.value });
          }}
          type="text"
        />
      </div>
      <div>
        <div className="label">Email</div>
        <Input
          value={formData["email"]}
          onChange={(e) => {
            setFormData({ ...formData, email: e.target.value });
          }}
          type="text"
        />
      </div>
      <div>
        <div className="label">Password</div>
        <Input
          value={formData["password"]}
          onChange={(e) => {
            setFormData({ ...formData, password: e.target.value });
          }}
          type="password"
        />
      </div>
      <button
        onClick={() => {
          let ObjToSet = { ...formData };
          Object.entries(ObjToSet).forEach(([key, val]) => {
            if (JSON.stringify(val) === JSON.stringify(user[key])) {
              delete ObjToSet[key];
            }
          });
          dispatch(AuthActions.editUser({ ...ObjToSet, id: user.id }));
        }}
      >
        Submit
      </button>
    </>
  );
};
export default EditUser;
