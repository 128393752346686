import { put, call, select, delay } from "redux-saga/effects";
import { AuthActions } from "../models";

import * as authReq from "services/auth";
import { notification } from "antd";
import { CommonActions } from "../models";

const isLocal = window.location.href.match(/localhost|192\.168/g);

export function* logOut() {
  const response = yield call(authReq.logOut);
  if (response.data) {
    localStorage.setItem("auth", "{}");
    yield put(AuthActions.setAuth({}));
    isLocal
      ? (window.location.href = "http://localhost:3001/")
      : (window.location.href = `${window.location.protocol}//itronix.tech/`);
  }
}

export function* getMain({ access_token }) {
  const response = yield call(authReq.fetchMain, access_token);
  if (response && response.data) {
    localStorage.setItem("auth", JSON.stringify({ ...response?.data, access_token }));
    yield put(AuthActions.setAuth({ ...response.data, access_token }));
    if (window.location.href.includes("access_token")) {
      window.history.pushState({}, document.title, "/#/");
    }
  }
}

export function* createCategory({ data }) {
  const response = yield call(authReq.createCategory, data);
  if (response.data) {
    // yield put(AuthActions.setCategories([...currCat, response.data]));
    yield put(AuthActions.getCategories());
    window.location.hash = `database/${response.data.id}`;
  }
}

export function* getCategories({ data }) {
  // console.log(data);
  const response = yield call(authReq.fetchCategories, data);
  // console.log(response);
  if (response.data) {
    yield put(AuthActions.setCategories(response.data));
  }
}

export function* getUsers({ data }) {
  // console.log(data);
  const response = yield call(authReq.getUsers, data);
  if (response.data) {
    yield put(AuthActions.setUsers(response.data));
  }
}

export function* deleteCatgory({ data }) {
  const response = yield call(authReq.deleteCatgory, data);
  if (response.data) {
    yield put(AuthActions.getCategories());
  }
}

export function* createTable({ data }) {
  const response = yield call(authReq.createTable, data);
  if (response.data) {
    yield put(AuthActions.getTables({ category_id: data.category_id }));
  }
}
export function* editTable({ data }) {
  const editColFields = Object.values(data.fields).filter((field) => field.old && !field.newCol);
  const newColFields = Object.values(data.fields).filter((field) => field.newCol);

  let r1 = {},
    r2 = {};
  if (newColFields.length) r1 = yield call(authReq.addColumns, { ...data, fields: newColFields });
  if (editColFields.length) r2 = yield call(authReq.editColumns, { ...data, fields: editColFields });

  if (r1.data || r2.data) {
    yield put(AuthActions.getTables({ category_id: data.category_id }));
  }
}

export function* getTables({ data }) {
  const response = yield call(authReq.getTables, data);
  if (response.data) {
    yield put(AuthActions.setTables(response.data?.tables));
  }
}

export function* deleteTable({ data }) {
  const response = yield call(authReq.deleteTable, data);
  if (response.data) {
    yield put(AuthActions.getTables({ category_id: data.category_id }));
  }
}

export function* uploadLead({ data, cb }) {
  const response = yield call(authReq.uploadLead, data);
  const tableDatas = yield select((s) => s.auth.tableDatas);
  if (response) {
    cb?.();
  }
  if (response.data) {
    notification.success({ message: `${response.data?.models?.length} rows uploaded`, placement: "topRight" });
    yield put(
      AuthActions.setTableRows({
        ...tableDatas,
        [data.table_id]: {
          rows: [...(tableDatas[data.table_id]?.rows || [])],
          count: +tableDatas[data.table_id]?.count + response.data?.models?.length,
        },
      }),
    );
    yield put(CommonActions.setPopUp({ status: false }));
  }
}

export function* getTableRows({ params }) {
  const response = yield call(authReq.getTableRows, params);
  const tableDatas = yield select((s) => s.auth.tableDatas);
  if (response) {
    yield put(CommonActions.setPopUp({ status: false }));
  }
  if (response.data) {
    yield put(
      AuthActions.setTableRows({
        ...tableDatas,
        [params.table_id]: {
          rows: [...(response.data?.details || [])],
          count: response.data?.count,
        },
      }),
    );
  }
}

export function* deleteRowTable({ data }) {
  const response = yield call(authReq.deleteRowTable, data);
  const tableDatas = yield select((s) => s.auth.tableDatas);
  if (response.data) {
    notification.success({ message: "Row deleted succesfully!", placement: "topRight" });
    yield put(
      AuthActions.setTableRows({
        ...tableDatas,
        [data.table_id]: {
          count: +tableDatas[data.table_id]?.count - 1,
          rows: (tableDatas[data.table_id]?.rows || []).filter((row) => !data.ids.includes(row.id)),
        },
      }),
    );
  }
}

export function* updateTableRow({ data }) {
  const response = yield call(authReq.updateTableRow, data);
  const tableDatas = yield select((s) => s.auth.tableDatas);
  if (response.data) {
    notification.success({ message: "Row updated succesfully!", placement: "bottomRight" });
    yield put(
      AuthActions.setTableRows({
        ...tableDatas,
        [data.table_id]: {
          count: tableDatas[data.table_id]?.count,
          rows: (tableDatas[data.table_id]?.rows || []).map((row) =>
            row.id === data.id ? { ...row, ...response.data } : row,
          ),
        },
      }),
    );
  }
}

export function* getTableModels() {
  const response = yield call(authReq.getTableModels);
  if (response.data) {
    yield put(AuthActions.setTableModels(response.data));
  }
}

export function* assignTable({ data }) {
  const response = yield call(authReq.assignTable, data);
  const currentTables = yield select((s) => s.auth.tables);

  if (response.data) {
    //
    yield put(
      AuthActions.setTables(
        currentTables.map((table) => {
          return table.id === data.table_id ? { ...table, model: response.data } : table;
        }),
      ),
    );
    yield put(
      CommonActions.setPopUp({
        status: false,
      }),
    );
  }
}

export function* transferRows({ data }) {
  const response = yield call(authReq.transferRows, data);
  if (response.data) {
    notification.success({ message: `${data.ids.length} row transfered successully!`, placement: "topRight" });
  }
}

export function* getRoles() {
  const response = yield call(authReq.getRoles);
  if (response.data) {
    //got roles
    yield put(AuthActions.setRoles(response.data));
  }
}

export function* updateRolePermission({ id, array, tipo }) {
  const response = yield call(authReq.updateRolePermission, id, array, tipo);
  yield delay(2000);
  if (response.data) {
    notification.success({ message: `Settings changed`, placement: "topRight" });
  }
}
export function* createRole({ data }) {
  const response = yield call(authReq.createRole, data);
  if (response.data) {
    //get roles
    yield put(AuthActions.getRoles());
  }
}

export function* createUser({ data }) {
  const response = yield call(authReq.createUser, data);
  if (response.data) {
    const users = yield select((s) => s.auth.users);
    notification.success({ message: `User created!`, placement: "topRight" });
    yield put(AuthActions.setUsers([...users, response.data]));
    yield put(
      CommonActions.setPopUp({
        status: false,
      }),
    );
  }
}
export function* deleteUser({ data }) {
  const response = yield call(authReq.deleteUser, data);
  if (response.data) {
    const users = yield select((s) => s.auth.users);
    notification.success({ message: `User deleted!`, placement: "topRight" });
    yield put(AuthActions.setUsers(users.filter((u) => +u.id !== +data.id)));
  }
}

export function* editCategory({ data }) {
  const response = yield call(authReq.editCategory, data);
  if (response.data) {
    yield put(
      CommonActions.setPopUp({
        status: false,
      }),
    );
    notification.success({ message: `Category updated!`, placement: "topRight" });
    yield put(AuthActions.getCategories());
  }
}
export function* getViciDialLists({ data }) {
  const response = yield call(authReq.getViciDialLists, data);
  if (response.data) {
    yield put(AuthActions.setViciDialLists(response.data));
  }
}

export function* editUser({ data }) {
  const response = yield call(authReq.editUser, data);

  if (response.data) {
    const users = yield select((s) => s.auth.users);
    yield put(AuthActions.setUsers(users.map((u) => (u.id === data.id ? { ...u, ...response.data } : u))));
    notification.success({ message: `User Edited!`, placement: "topRight" });
    yield put(CommonActions.setPopUp({ status: false }));
  }
}
