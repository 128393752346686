import React, { useEffect, useState } from "react";
import "./SideBar.scss";
import { useLocation, withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { CommonActions } from "reducers/models";
import { getCatRead } from "services/common";


const CatsRead = ({ cat, paramId }) => {
  const [isOpen, setOpen] = useState(false);
  

  return (
    <>
      <div
        className={"catBtn " + (+cat.company_category_id === +paramId ? " active" : "")}
        onClick={() => {
          window.location.hash = `/database/read/${cat.company_category_id}`;
        }}
      >
        {cat?.childrens_recursive?.length ? (
          <i
            onClick={(e) => {
              e.stopPropagation();
              setOpen(!isOpen);
            }}
            className={`fal fa-chevron-${isOpen ? "down" : "right"}`}
            aria-hidden="true"
          ></i>
        ) : (
          <i className="" aria-hidden="true"></i>
        )}
        <div className="name">{cat && cat.name.includes("{") ? JSON.parse(cat.name).en : cat.name}</div>
      </div>
      {isOpen && (
        <div className="dropDown">
          {cat?.childrens_recursive?.length
            ? cat.childrens_recursive.map((cat2) => {
              return <Category cat={cat2} paramId={paramId} />;
            })
            : null}
        </div>
      )}
    </>
  );
}

//&& JSON.parse(cat.name).en
const Category = ({ cat, paramId }) => {
  const [isOpen, setOpen] = useState(false);


  return (
    <>
      <div
        className={"catBtn " + (+cat.id === +paramId ? " active" : "")}
        onClick={() => {
          window.location.hash = `/database/${cat.id}`;
        }}
      >
        {cat?.childrens_recursive?.length ? (
          <i
            onClick={(e) => {
              e.stopPropagation();
              setOpen(!isOpen);
            }}
            className={`fal fa-chevron-${isOpen ? "down" : "right"}`}
            aria-hidden="true"
          ></i>
        ) : (
          <i className="" aria-hidden="true"></i>
        )}
        <div className="name">{cat.name}</div>
      </div>
      {isOpen && (
        <div className="dropDown">
          {cat?.childrens_recursive?.length
            ? cat.childrens_recursive.map((cat2) => {
              return <Category cat={cat2} paramId={paramId} />;
            })
            : null}
        </div>
      )}
    </>

  );
};

const SideBar = ({ match, setPopUp }) => {
  const paramId = match?.params?.id;
  const categories = useSelector((state) => state.auth.categories);

  const [cats, setCats] = useState([])

  useEffect(() => {
    getCatRead().then(res => {
      setCats(res.data)
    })
  }, [])

  return (
    <>
      <div className="sidenav d-flex flex-column">
        <div className="h5_">DATABASE</div>

        <div className="links-wrapp">
          {categories !== undefined && categories?.map((cat) => {
            return <Category paramId={paramId} cat={cat} key={cat.id} />;
          })}
        </div>
        <div className="h5_" >Read Only Categories</div>

        <div className="links-wrapp">
          {cats !== undefined && cats?.map((cat) => {
            return <CatsRead paramId={paramId} cat={cat} key={cat.id} />;
          })}
        </div>

        {window.location.hash.includes("/database") && (
          <button
            className="createCat"
            onClick={() => {
              setPopUp({ status: true, type: "create_category" });
            }}
          >
            <i className="fal fa-plus" aria-hidden="true"></i> Create Category
          </button>
        )}


      </div>
    </>
  );
};

export default withRouter(connect(null, CommonActions)(SideBar));



