import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Input } from "antd";
import { AuthActions } from "reducers/models";
const CreateUser = ({ data: { role_id } }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ role_id });
  return (
    <>
      <div>
        <div className="label">First Name</div>
        <Input
          value={formData["first_name"]}
          onChange={(e) => {
            setFormData({ ...formData, first_name: e.target.value });
          }}
          type="text"
        />
      </div>
      <div>
        <div className="label">Last Name</div>

        <Input
          value={formData["last_name"]}
          onChange={(e) => {
            setFormData({ ...formData, last_name: e.target.value });
          }}
          type="text"
        />
      </div>
      <div>
        <div className="label">Email</div>
        <Input
          value={formData["email"]}
          onChange={(e) => {
            setFormData({ ...formData, email: e.target.value });
          }}
          type="text"
        />
      </div>
      <div>
        <div className="label">Password</div>
        <Input
          value={formData["password"]}
          onChange={(e) => {
            setFormData({ ...formData, password: e.target.value });
          }}
          type="password"
        />
      </div>
      <button
        onClick={() => {
          dispatch(AuthActions.createUser(formData));
        }}
      >
        Submit
      </button>
    </>
  );
};
export default CreateUser;
