import { useState } from "react";
import "./CreateCategory.scss";
import { AuthActions, CommonActions } from "reducers/models";
import { connect, useSelector } from "react-redux";
import { Input, Select, Radio } from "antd";
import { b64toBlob } from "utils/index";
import { TreeSelect } from "antd";

const { TreeNode } = TreeSelect;
const extractCat = (categories) => {};

const renderChildrens = (cats) => {
  return (cats || []).map((cat) => {
    return (
      <TreeNode value={cat.id} title={cat.name}>
        {cat.childrens_recursive?.length && renderChildrens(cat.childrens_recursive)}
      </TreeNode>
    );
  });
};

const CreatCatComp = ({ categories, createCategory, getUsers }) => {
  const [formData, setFormData] = useState({ isSubCat: false });
  const roles = useSelector((s) => s.auth.auth.user?.subroles);
  const users = useSelector((s) => s.auth.users);
  console.log("extractCat", extractCat(categories));

  return (
    <>
      <div>
        <div className="label">Sub Category</div>
        <div>
          <Radio.Group
            onChange={(e) => {
              setFormData({ ...formData, isSubCat: e.target.value });
            }}
            value={formData.isSubCat}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </div>
      </div>
      {formData.isSubCat && (
        <div className="animate__animated animate__fadeInLeft">
          <div className="label">Select parent Category : </div>

          <TreeSelect
            onChange={(e) => {
              setFormData({ ...formData, parent_id: e });
            }}
          >
            {(categories || []).map((cat) => {
              return (
                <TreeNode value={cat.id} title={cat.name}>
                  {renderChildrens(cat.childrens_recursive)}
                </TreeNode>
              );
            })}
          </TreeSelect>
        </div>
      )}
      <div>
        <div className="label">Name : </div>
        <Input
          value={formData.name}
          onChange={(e) => {
            setFormData({ ...formData, name: e.target.value });
          }}
          type="text"
        />
      </div>
      <div>
        <div className="label">Select role :</div>
        <Select
          onChange={(e) => {
            getUsers({ role_id: e });
            setFormData({ ...formData, role_id: e });
          }}
        >
          {(roles || []).map((r) => {
            return (
              <Select.Option key={r.id} value={r.id}>
                {r.name}
              </Select.Option>
            );
          })}
        </Select>
      </div>
      <div>
        <div className="label">Assign To :</div>
        <Select
          mode="tags"
          onChange={(e) => {
            setFormData({ ...formData, user_ids: e });
          }}
        >
          {users.map((u) => {
            return (
              <Select.Option value={u.id} key={u.id}>
                {u.first_name} {u.last_name}
              </Select.Option>
            );
          })}
        </Select>
      </div>
      <div>
        <div className="label">Upload Image : </div>{" "}
        <label htmlFor="upl">
          {formData.imageObj ? (
            <img src={formData?.imageObj?.url} alt="" />
          ) : (
            <i className="fal fa-upload" aria-hidden="true"></i>
          )}
        </label>
        <input
          type="file"
          id="upl"
          onChange={(e) => {
            const files = e.target.files;
            for (const file of files) {
              const reader = new FileReader();
              reader.onload = function (e) {
                const b64 = e.target.result;
                const url = b64toBlob(b64, file.type)[0];
                const blobi = b64toBlob(b64, file.type)[1];
                setFormData({
                  ...formData,
                  imageObj: {
                    file: file,
                    id: `${file.name}${file.size}`,
                    name: file.name,
                    url,
                    blobi,
                    type: file.type,
                  },
                  image: b64.split(",")[1],
                });
              };
              reader.readAsDataURL(file);
            }
          }}
        />
      </div>
      <button
        onClick={() => {
          createCategory(formData);
        }}
      >
        Submit
      </button>
    </>
  );
};

export default connect(({ auth }) => ({ categories: auth.categories }), { ...AuthActions, ...CommonActions })(
  CreatCatComp,
);
