import React, { useState, useEffect } from "react";
import { Checkbox, Select } from "antd";
import { DownOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import { CheckedButton } from "components/Inputs";
import { connect } from "react-redux";
import { AuthActions } from "reducers/models";
import { useSelector } from "react-redux";

const { Option } = Select;

const initialParams = [
  { content: "First Name", param_name: "first_name", id: 0, selected: false },
  { content: "Last Name", param_name: "last_name", id: 1, selected: false },
  { content: "Phone", param_name: "phone", id: 2, selected: false },
  { content: "Email", param_name: "email", id: 3, selected: false },
];
const ExcelAlphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "..."];

const UploadExel = ({ data, uploadLead, getTableModels, getViciDialLists, viciDialLists }) => {
  const [TableHeaders, setTableHeaders] = useState([]);
  const [params, setParams] = useState(initialParams);
  const [formData, setFormData] = useState({});
  const [disabledButton, setDisabledButton] = useState(false);
  const [allData, setAll] = useState({ status: false });
  const tableModels = useSelector((state) => state.auth.tableModels);
  const { lead_lists, lead_statuses } = useSelector((s) => s.auth.auth);



  useEffect(() => {
    console.log({ lead_lists, lead_statuses })
  }, [lead_lists, lead_statuses])

  const handleSubmit = () => {
    let selectedColumns = TableHeaders.filter((el) => el.selected);
    setDisabledButton(true);
    console.log(allData)
    uploadLead(
      {
        ...(selectedColumns || []).reduce((a, b) => ({ ...a, [b.param_name]: b.id }), {}),
        file: formData,
        table_id: data.id,
        ...allData,
      },
      () => {
        setDisabledButton(false);
      },
    );
  };

  const readExcel = (file) => {
    setFormData(file);
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (param) => {
        try {
          const bufferArray = param.target.result;
          const wb = XLSX.read(bufferArray, { type: "buffer" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const SheetJSONData = XLSX.utils.sheet_to_json(ws, { header: 1 });
          resolve(SheetJSONData);
          const [startCol, endCol] = ws["!ref"].split(":") || [];
          const columnsSkiped = ExcelAlphabet.indexOf(startCol.charAt(0));
          // console.log("SheetJSONData", columnsSkiped, ws, SheetJSONData);
        } catch (err) {
          console.log("errrrr", err);
        }
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      let tmpArray = [];

      tmpArray = d[0].map((val, ind) => {
        return {
          text: val,
          selected: false,
          id: ind,
        };
      });

      // console.log("ca ka promise d", d);
      setTableHeaders(tmpArray);
    });
  };

  const handleReset = () => {
    setTableHeaders([]);
    setFormData(null);
  };
  useEffect(() => {
    if (allData.status === false && allData.vicidial_list_id) {
      setAll({ vicidial_list_id: allData.vicidial_list_id })
    } else if (allData.status === false) {
      setAll({})
    }
  }, [allData]);

  useEffect(() => {
    const headers = JSON.parse(data.detail || "[]");

    setParams(
      headers.map((h, i) => ({
        param_name: "",
        content: h.name,
        id: i,
        selected: false,
      })),
    );
  }, [data]);
  useEffect(() => {
    getTableModels();
    getViciDialLists();
  }, []);
  return (
    <div>
      <div className="header">Upload Excel</div>
      {TableHeaders.length < 1 && (
        <div>
          <label htmlFor="inpupl">
            <i className="fal fa-upload" aria-hidden="true"></i>
          </label>
          <input id="inpupl" type="file" onChange={(e) => readExcel(e.target.files[0])} />
        </div>
      )}

      {TableHeaders.length >= 1 && (
        <div>
          <span className="d-flex gap-2 align-items-center">
            <div className="mt-3 mb-2">Select Table Columns</div>
            <div className="reset-btn px-2 py-1" onClick={handleReset}>
              Reset
            </div>
          </span>
          {params.map((param) => (
            <div className="row my-2" key={param.id}>
              <CheckedButton
                className="col-3"
                text={param.content}
                textClassName={"checkbox-text"}
                selected={param.selected}
                onChange={(vl) => setParams(params.map((el, i) => (param.id === i ? { ...el, selected: !vl } : el)))}
              />
              <div className="col-9">
                <Select
                  disabled={!param.selected}
                  name="list_id"
                  showSearch
                  filterOption={(input, option) => {
                    return option.searchF?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                  className="w-100"
                  suffixIcon={<DownOutlined />}
                  onChange={(value) => {
                    setTableHeaders(
                      TableHeaders.map((el, i) =>
                        value === i
                          ? {
                            ...el,
                            selected: true,
                            param_name: param.content,
                          }
                          : el,
                      ),
                    );
                  }}
                >
                  {TableHeaders.map((list, index) => (
                    <Option disabled={list.selected} key={index} value={index} searchF={`${list.text}`}>
                      {list.text}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          ))}
          {data.model?.name && (
            <Checkbox
              checked={allData.status}
              onChange={(e) => {
                setAll({ ...allData, status: e.target.checked });
              }}
            >
              Transfer all rows
            </Checkbox>
          )}

          {allData.status && (
            <>
              {/* <div>
                <span>Select Table</span>
                <Select
                  onChange={(_, b) => {
                    setAll({ ...allData, table_action_id: b.data.id });
                  }}
                >
                  {(tableModels || []).map((mdl) => {
                    return (
                      <Select.Option key={mdl.id} value={mdl.id} data={mdl}>
                        {mdl.name} ({mdl.description})
                      </Select.Option>
                    );
                  })}
                </Select>
              </div> */}
              <div>
                <span>Select Status</span>
                <Select
                  onChange={(e) => {
                    setAll({ ...allData, status_id: e });
                  }}
                >
                  {lead_statuses.map((item) => {
                    return (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
              <div>
                <span>Select List</span>
                <Select
                  onChange={(e) => {
                    setAll({ ...allData, list_id: e });
                  }}
                >
                  {lead_lists.map((item) => {
                    return (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>

            </>
          )}
          <div>
            <span>Vicidial List</span>
            <Select
              onChange={(e) => {
                setAll(prev => ({ ...prev, vicidial_list_id: e, status: false }))
              }}
            >
              {viciDialLists.map((item) => {
                return (
                  <Select.Option key={item.list_id} value={item.list_id}>
                    {item.list_name}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
        </div>
      )}

      <div>
        <button className="submit" onClick={handleSubmit}>
          {disabledButton ? "Loading..." : "Submit"}
        </button>
      </div>
    </div>
  );
};

export default connect(({ auth: { viciDialLists } }) => ({ viciDialLists }), AuthActions)(UploadExel);
