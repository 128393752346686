import React, { useState, useEffect } from "react";
import { SideBar, TopMenuRight, PopUp } from "components";
import "./style.scss";
import { withRouter, useLocation } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { endpoint } from "config/api";
import { AuthActions, CommonActions } from "reducers/models";
import Table from "./Table";
import CreateTable from "./CreateTable";
import { Popconfirm } from "antd";
import { getTableCatRead } from "../../services/common"
import TableRead from "./TableRead";
const flatCategories = (cats) => {
  return cats.map((c) => (c.childrens_recursive?.length ? [c, ...flatCategories(c.childrens_recursive)] : c));
};

const DataBase = ({ match, deleteCatgory, setPopUp, getTables }) => {
  //
  const [isRead, setIsRead] = useState(true)
  const location = useLocation()
  const [readTables, setReadTables] = useState([])
  const [headersRead,setHeadersRead] = useState([])
  useEffect(() => {
    if (location.pathname.split("/").some(item => item === "read")) {
      setIsRead(true)
      getTableCatRead(paramId).then(res => {
        if (res.err) {
          setReadTables([])
          setHeadersRead([])
        } else {
          setReadTables(res.data)
        }

      })
    } else {
      setIsRead(false)
      console.log('test')
      setPopUp(false);
      if (paramId) getTables({ category_id: paramId });
      setCreate(false);
    }
  }, [location])
  const tables = useSelector((s) => s.auth.tables);
  const categories = useSelector((s) => s.auth.categories);
  const [toCreate, setCreate] = useState(false);

  const paramId = match?.params?.id;


  const currentCategory =
    categories
      .map((c) => (c.childrens_recursive?.length ? [c, ...flatCategories(c.childrens_recursive)] : c))
      .flat(100)
      .find((c) => +c.id === +paramId) || {};

  // console.log("currentCategory", categories, paramId, currentCategory);
  // console.log("tableData", tableData);
  return (
    <div className="database">
      <div className="database-left">
        <SideBar />
      </div>
      <div className="database-right">
        {isRead ?

          <>
            <PopUp />
            <TopMenuRight />
            {readTables.map(item => (
              <TableRead tableId={item.id} name={item.name} header={JSON.parse(item.detail)} />
            ))}
          </>
          :
          <>
            <PopUp />
            <TopMenuRight />
            {currentCategory.id ? (
              <>
                <div className="database-info">
                  <img src={endpoint + currentCategory.image} alt="" />
                  <span>{currentCategory.name}</span>
                  <button
                    className="editBtn"
                    onClick={() => {
                      setPopUp({
                        type: "edit_category",
                        status: true,
                        data: currentCategory,
                      });
                    }}
                  >
                    <i className="fal fa-pen" aria-hidden="true"></i>Edit Category
                  </button>
                  <Popconfirm
                    okText="Yes"
                    cancelText="No"
                    title="Are you sure to delete this category?"
                    onConfirm={() => {
                      deleteCatgory({ id: currentCategory.id });
                    }}
                  >
                    <button className="delBtn">
                      <i className="fal fa-trash" aria-hidden="true"></i>Delete Category
                    </button>
                  </Popconfirm>
                </div>
                <div className="table-view">
                  {tables.length ? (
                    <div className="tables">
                      <button
                        className="addTable"
                        onClick={() => {
                          setCreate(!toCreate);
                        }}
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i> Add table
                      </button>
                      {tables.map((table, ind) => {
                        return <Table ind={ind} key={table.id} table={table} currentCategory={currentCategory} />;
                      })}
                    </div>
                  ) : (
                    <div className="noTable">
                      <span>No table for this database</span>
                      <button
                        onClick={() => {
                          setCreate(true);
                        }}
                      >
                        Create table <i className="fas fa-plus" aria-hidden="true"></i>
                      </button>
                    </div>
                  )}
                  {toCreate && <CreateTable currentCategory={currentCategory} />}
                </div>
              </>
            ) : (
              <div className="noCatSelected">
                <i className="fal fa-outdent" aria-hidden="true"></i>
                Category not found!
              </div>
            )}

          </>
        }

      </div>
    </div>
  );
};

export default withRouter(connect(null, { ...AuthActions, ...CommonActions })(DataBase));
