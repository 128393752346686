import { Select, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const AssignTable = ({ data, getTableModels, assignTable }) => {
  const tableModels = useSelector((state) => state.auth.tableModels);
  const [selectedModel, setSselectedModel] = useState({});
  useEffect(() => {
    getTableModels();
  }, []);
  return (
    <>
      <div className="assignTable">
        <div className="header">Assign Table `{data.table.name.split("db_file_")[1]}` to : </div>
        <div>
          <div className="label">Select Model</div>
          <Tooltip title={selectedModel.description}>
            <Select
              onChange={(_, b) => {
                setSselectedModel(b.data);
              }}
            >
              {(tableModels || []).map((mdl) => {
                return (
                  <Select.Option key={mdl.id} value={mdl.id} data={mdl}>
                    {mdl.name} ({mdl.description})
                  </Select.Option>
                );
              })}
            </Select>
          </Tooltip>
        </div>
      </div>
      <button
        onClick={() => {
          assignTable({
            table_id: data.table.id,
            table_action_id: selectedModel.id,
          });
        }}
      >
        Submit
      </button>
    </>
  );
};

export default AssignTable;
