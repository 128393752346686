import React, { useEffect, useState } from "react";
import { PopUp, TopMenuRight, SideBar } from "components";
import { connect, useSelector } from "react-redux";
import { AuthActions } from "reducers/models";
import "./styles.scss";
import RolesView from "./RolesView";
import Users from "./Users";

const RoleComp = ({ role, activeRole, setActive }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <div
        className={"roleBtn " + (+role.id === activeRole.id ? " active" : "")}
        onClick={() => {
          setActive(role);
        }}
      >
        {role?.subroles?.length ? (
          <i
            onClick={(e) => {
              e.stopPropagation();
              setOpen(!isOpen);
            }}
            className={`fal fa-chevron-${isOpen ? "down" : "right"}`}
            aria-hidden="true"
          ></i>
        ) : (
          <i className="" aria-hidden="true"></i>
        )}
        <div className="name">{role.name}</div>
      </div>
      {isOpen && (
        <div className="dropDown">
          {role?.subroles?.length
            ? role.subroles.map((role2) => {
                return <RoleComp activeRole={activeRole} setActive={setActive} role={role2} />;
              })
            : null}
        </div>
      )}
    </>
  );
};
const Roles = ({ getRoles, roles, updateRolePermission, createRole }) => {
  const [activeRole, setActive] = useState({});
  const permissions = useSelector((state) => state.auth?.auth?.permissions?.["DATABASE"]);
  const [currentRolePermissions, setPermissions] = useState([]);
  const [activeTab, setTab] = useState(0);
  const [createForm, setCreateForm] = useState({});
  const currentUserRID = useSelector((state) => state.auth?.auth?.user?.role?.id);
  useEffect(() => {
    getRoles();
    if (activeRole.permissions) setPermissions(activeRole.permissions);
  }, [activeRole.id]);

  useEffect(() => {
    if (activeTab === 2) {
      setPermissions([]);
    }
  }, [activeTab]);

  return (
    <div className="database">
      <div className="database-left">
        <SideBar />
      </div>
      <div className="database-right">
        <PopUp />
        <TopMenuRight />
        <div className="roles">
          <div className="roles-left">
            <div className="links-wrapp">
              {(roles || []).map((role) => {
                return <RoleComp activeRole={activeRole} setActive={setActive} role={role} key={role.id} />;
              })}
            </div>
          </div>
          <div className="roles-right">
            <div className="header">
              {activeRole.id && (
                <div
                  onClick={() => {
                    setTab(0);
                    setActive({});
                  }}
                >
                  <i className="fal fa-user-slash"></i>
                </div>
              )}
              <div onClick={() => setTab(0)} className={activeTab === 0 ? " active" : ""}>
                Permissions {activeRole.name && `for ${activeRole.name}`}
              </div>
              <div onClick={() => setTab(1)} className={activeTab === 1 ? " active" : ""}>
                Users {activeRole.name && `for ${activeRole.name}`}
              </div>
              <div onClick={() => setTab(2)} className={activeTab === 2 ? " active" : ""}>
                Create new {activeRole.id ? "subrole" : "role"}
              </div>
            </div>
            {activeTab === 0 &&
              (activeRole.id ? (
                <RolesView
                  permissions={permissions}
                  currentRolePermissions={currentRolePermissions}
                  setPermissions={setPermissions}
                  updateRolePermission={updateRolePermission}
                  activeRole={activeRole}
                />
              ) : (
                <div className="noRole">Select role on left side to set permissions</div>
              ))}
            {activeTab === 1 &&
              (activeRole.id ? (
                <Users activeRoleId={activeRole.id} />
              ) : (
                <div className="noRole">Select role on left side to get users</div>
              ))}
            {activeTab === 2 && (
              <div className="createRole">
                <div>
                  <div className="label">Name</div>
                  <input
                    value={createForm["name"]}
                    onChange={(e) => {
                      setCreateForm({
                        ...createForm,
                        ["name"]: e.target.value,
                      });
                    }}
                    type="text"
                    className="ant-input"
                  />
                </div>
                <RolesView
                  permissions={permissions}
                  currentRolePermissions={currentRolePermissions}
                  setPermissions={setPermissions}
                  updateRolePermission={updateRolePermission}
                  activeRole={activeRole}
                  toCreate
                />
                <div
                  className="submit"
                  onClick={() => {
                    createRole({
                      name: createForm["name"],
                      permission_id: currentRolePermissions.toString(),
                      parent_role_id: activeRole.id || currentUserRID,
                    });
                  }}
                >
                  <i className="fal fa-user-plus" aria-hidden="true"></i> Create
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(({ auth }) => ({ roles: auth.roles }), AuthActions)(Roles);
