import React, { useEffect } from "react";
import { Switch, HashRouter } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import * as Page from "pages";

import { AuthActions } from "reducers/models";
import { useDispatch, useSelector } from "react-redux";

const Router = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.auth);
  var url = new URL(window.location.href);
  const urlToken = url.searchParams.get("access_token");
  useEffect(() => {
    dispatch(AuthActions.getMain(urlToken || auth?.access_token));
    dispatch(AuthActions.getCategories());
  }, []);

  return (
    <HashRouter>
      <Switch>
        <PrivateRoute exact path={["/", "/home"]} component={Page.HomePage} />
        <PrivateRoute exact path={["/database", "/database/:id", "/database/read/:id"]} component={Page.DataBase} />
        <PrivateRoute exact path={["/roles"]} component={Page.Roles} />
      </Switch>
    </HashRouter>
  );
};

export default Router;
