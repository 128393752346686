import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import { reducer as AuthReducer } from "./models/auth";
import { reducer as CommonActions } from "./models/common";

import rootSaga from "./saga";

const rootReducer = combineReducers({
  auth: AuthReducer,
  common: CommonActions,
});

export default function configureStore() {
  const loggerMiddleware = (store) => (next) => (action) => {
    return next(action);
  };
  const composeEnhancers = (typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware, loggerMiddleware];

  return {
    ...createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares))),
    runSaga: sagaMiddleware.run,
  };
}
export const store = configureStore();
store.runSaga(rootSaga);
window.store = store;
