import React from "react";
import { Home } from "../../components";

const HomePage = () => {
  return (
    <div>
      <Home />
    </div>
  );
};

export default HomePage;
