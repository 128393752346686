import { createActions, createReducer } from "reduxsauce";

const { Types, Creators } = createActions({
  takeScreenWidth: ["screenWidth"],
  setDevice: ["deviceType"],
  setMobileCheck: ["mobileCheck"],
  setPopUp: ["hasPopUp"],
  setFilterData: ["filterData"],
});

export const CommonTypes = Types;
export default Creators;

const INITIAL_STATE = {
  deviceType: "unknown",
  mobileCheck: true,
  screenWidth: window.innerWidth,
  language: localStorage.getItem("language") || "en",
  hasPopUp: {
    type: "",
    status: false,
  },
  filterData: {},

  // data: [],
};

export const reducer = createReducer(INITIAL_STATE, {
  SET_POP_UP: (state, { hasPopUp }) => ({
    ...state,
    hasPopUp,
  }),
  SET_FILTER_DATA: (state, { filterData }) => ({
    ...state,
    filterData,
  }),
});
