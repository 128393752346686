import React from "react";
import { png } from "../../assets";
import { svg } from "assets";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "./Home.scss";

const Home = () => {
  return (
    <div className="homepage">
      <h1>Database</h1>
      <div>
        <div
          onClick={() => {
            window.location.hash = "database";
          }}
        >
          Categories
        </div>
        <div
          onClick={() => {
            window.location.hash = "roles";
          }}
        >
          Roles
        </div>
      </div>
    </div>
  );
};

export default Home;
