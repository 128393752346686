import axios from "axios";
import { notification } from "antd";
import api from "../config/api";
import React from "react";
const isLocalHost = window.location.href.match(/localhost|192/g);

const hasCode = (error, status) => {
  if (
    error?.response?.status === parseInt(status) ||
    error.error?.response?.status === parseInt(status) ||
    error?.response?.status === parseInt(status)
  ) {
    return true;
  } else {
    return false;
  }
};
export const HandleError = (error) => {
  console.log("error handler", error.response);
  if (error?.message === "Network Error") {
    notification["error"]({
      key: "networkErr",
      message: `Network error`,
      icon: <i className="fal fa-wifi-slash"></i>,
      duration: 0,
    });
  } else if (error.config?.url === "/api/user/timer") {
    //timer error request
    //
    //
  } else if (hasCode(error, 403)) {
    //forbiden || unauthorized
    if (isLocalHost) {
    } else {
      localStorage.removeItem("auth");
      window.store.dispatch({ type: "SET_UNAUTHORIZATION" });
      window.location.href.match(/192|localhost/g)
        ? (window.location.href = `http://localhost:3001/`)
        : (window.location.href = `${window.location.protocol}//itronix.tech/`);
    }
    notification["error"]({
      message: error?.response?.data?.message || error.message,
      description: error?.response?.data?.error && Object.values(error.response.data.error),
      placement: window.innerWidth <= 1024 ? "topRight" : "bottomRight",
      duration: 4,
    });
  } else {
    notification["error"]({
      message: error?.response?.data?.message || error.message,
      description: error?.response?.data?.error && Object.values(error.response.data.error),
      placement: window.innerWidth <= 1024 ? "topRight" : "bottomRight",
      duration: 4,
    });
  }
  return Promise.reject(error);
};

const instanceAxios = axios.create({
  baseURL: api.baseUrl,
  headers: api.headers,
});

instanceAxios.interceptors.request.use(
  (config) => {
    //public apis without token
    var Auth = true;
    if (
      config.url === "/api/login" ||
      config.url === "/api/domain/currencies" ||
      config.url === "/api/client/register" ||
      config.url === "/api/client/login"
    ) {
      //api -> without token
      Auth = false;
    }
    const value = localStorage.getItem("auth") || "{}";
    const keys = JSON.parse(value);
    var url = new URL(window.location.href);
    const urlToken = url.searchParams.get("access_token");
    config.headers = {
      ...(Auth ? { Authorization: `Bearer ${urlToken || keys?.access_token}` } : {}),
      Accept: "*/*",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);
instanceAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => HandleError(error),
);

export default instanceAxios;
