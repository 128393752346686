import React from "react";
import { connect } from "react-redux";
import { AuthActions, CommonActions } from "reducers/models";
import { useSelector } from "react-redux";
import UploadExel from "components/UploadExel";
import {
  CreateCategory,
  EditTableRow,
  AssignTable,
  FilterTable,
  CreateUser,
  EditCategory,
  EditTable,
  TransferRows,
  EditUser,
} from "components";
const PopUp = ({ setPopUp, getTableModels, assignTable }) => {
  const hasPopUp = useSelector((s) => s.common.hasPopUp);
  return hasPopUp.status ? (
    <div
      className="popup"
      onClick={() => {
        setPopUp({ status: false });
      }}
    >
      <div
        data-popup={hasPopUp.type}
        className="popup-content"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {hasPopUp.type === "create_category" && <CreateCategory />}
        {hasPopUp.type === "create_user" && <CreateUser data={hasPopUp.data} />}
        {hasPopUp.type === "edit_user" && <EditUser data={hasPopUp.data} />}
        {hasPopUp.type === "upload_excel" && <UploadExel data={hasPopUp.data} />}
        {hasPopUp.type === "edit_table" && (
          <EditTable currentCategory={hasPopUp.currentCategory} table={hasPopUp.data} />
        )}
        {hasPopUp.type === "edit_table_row" && <EditTableRow />}
        {hasPopUp.type === "assign_table" && (
          <AssignTable assignTable={assignTable} getTableModels={getTableModels} data={hasPopUp.data} />
        )}
        {hasPopUp.type === "filter_table" && <FilterTable getTableRows={hasPopUp.getTableRows} data={hasPopUp.data} />}
        {hasPopUp.type === "edit_category" && <EditCategory data={hasPopUp.data} />}
        {hasPopUp.type === "transfer_rows" && (
          <TransferRows transferRows={hasPopUp.transferRows} data={hasPopUp.data} />
        )}
      </div>
    </div>
  ) : (
    false
  );
};
export default connect(({ auth }) => ({ categories: auth.categories }), { ...CommonActions, ...AuthActions })(PopUp);
