import React from "react";
import "./checkedButton.scss";

const CheckedButton = ({
  selected,
  onChange,
  text,
  className,
  disabled,
  textClassName,
}) => {
  const handleClick = () => {
    onChange(selected);
  };

  return (
    <div
      className={"check-container " + className}
      style={disabled ? { filter: "grayscale(1)", pointerEvents: "none" } : {}}
      onClick={handleClick}
    >
      <div className="check-icon">
        <span className={selected ? "active " : ""}>&#10003;</span>
      </div>
      <p className={textClassName}>{text}</p>
    </div>
  );
};

export default CheckedButton;
