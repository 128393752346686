import CreateTable from "pages/Database/CreateTable";
const EditTable = ({ table, currentCategory }) => {
  return (
    <div className="">
      <div className="header">Edit Table</div>
      <CreateTable currentTableData={table} currentCategory={currentCategory} toEdit />
    </div>
  );
};

export default EditTable;
