import React, { useState, useEffect } from "react";
import { Collapse, Table, Pagination } from "antd";
import { getTableReadDetails } from "services/common";

export default function TableRead({ tableId, name, header }) {
    const { Panel } = Collapse;
    const [start, setStart] = useState("0");
    const [take, setTake] = useState(10);
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    useEffect(() => {
        const map = header.map((item, index) => {
            return {
                title: `${item.name} (${item.type})`,
                dataIndex: `${item.name}`,
                key: `${item.name}`,
            };
        });
        setColumns(map);
    }, [header]);

    useEffect(() => {
        if (tableId) {
            getTableReadDetails(tableId, start, take).then((res) => {
                if (!res.err) {
                    setData(res.data.details.map((item) => ({ key: item.id, ...item })));
                    setCount(res.data.count);
                }
            });
        }
    }, [tableId,start,take]);

    return (
        <>
            <div

                style={{
                    padding: "10px 20px ",
                }}
            >

                
                <div className="table-read">
                    <Collapse>
                        <Panel
                            header={name.split("_")[name.split("_").length - 1]}
                            key={tableId}
                        >
                            <Table columns={columns} dataSource={data} />
                            <Pagination
                                showSizeChanger
                                pageSize={take}
                                onShowSizeChange={(e, size) => {
                                    setTake(size);
                                }}
                                total={count}
                                current={start === "0" ? 1 : +start + 1}
                                onChange={(e) => {
                                    setStart(e - 1);
                                }}
                            />
                        </Panel>
                    </Collapse>
                </div>
            </div>
        </>
    );
}
