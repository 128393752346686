import React from "react";
import { Switch } from "antd";

const uniq = (aa) => aa.filter((a, b, c) => c.indexOf(a) === b);

const RolesView = ({
  permissions,
  currentRolePermissions,
  setPermissions,
  updateRolePermission,
  activeRole,
  toCreate,
}) => {
  return (
    <>
      <div className="roles-permissions">
        {Object.entries(permissions).map(([category, categoryPermissions]) => {
          let wholeCatSelected = true;
          categoryPermissions.forEach((pms) => {
            if (!(currentRolePermissions || []).includes(pms.id)) {
              wholeCatSelected = false;
            }
          });
          return (
            <div className="permissionBlock">
              <div
                onClick={() => {
                  if (wholeCatSelected) {
                    setPermissions(
                      uniq(currentRolePermissions.filter((pms) => !categoryPermissions.map((p) => p.id).includes(pms))),
                    );
                    //
                    if (!toCreate)
                      updateRolePermission(
                        activeRole.id,
                        (categoryPermissions || []).map((p) => p.id),
                        "detach",
                      );
                  } else {
                    setPermissions(uniq([...currentRolePermissions, ...(categoryPermissions || []).map((p) => p.id)]));
                    //
                    if (!toCreate)
                      updateRolePermission(
                        activeRole.id,
                        (categoryPermissions || []).map((p) => p.id),
                        "attach",
                      );
                  }
                }}
                className={"permissionBlock-header" + (wholeCatSelected ? " active" : "")}
              >
                {category}
              </div>
              <div className="permissionBlock-body">
                {(categoryPermissions || []).map((pms) => {
                  return (
                    <div key={pms.id}>
                      {pms.name}
                      <Switch
                        checked={(currentRolePermissions || []).includes(+pms.id)}
                        onChange={(e) => {
                          let arrayToSet = [];
                          if (e) {
                            arrayToSet = [...currentRolePermissions, pms.id];
                            if (!toCreate) updateRolePermission(activeRole.id, +pms.id, "attach");
                          } else {
                            arrayToSet = currentRolePermissions.filter((p) => +p !== +pms.id);
                            if (!toCreate) updateRolePermission(activeRole.id, +pms.id, "detach");
                          }
                          setPermissions(uniq(arrayToSet));
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default RolesView;
