import { takeLatest, all, takeEvery } from "redux-saga/effects";

/* ------------- Types ------------- */
import * as authGen from "./AuthSagas";

export default function* root() {
  yield all([
    // AUTH
    takeLatest("GET_MAIN", authGen.getMain),
    takeLatest("CREATE_CATEGORY", authGen.createCategory),
    takeLatest("GET_USERS", authGen.getUsers),
    takeLatest("GET_CATEGORIES", authGen.getCategories),
    takeLatest("DELETE_CATGORY", authGen.deleteCatgory),
    takeLatest("CREATE_TABLE", authGen.createTable),
    takeLatest("EDIT_TABLE", authGen.editTable),
    takeLatest("GET_TABLES", authGen.getTables),
    takeLatest("DELETE_TABLE", authGen.deleteTable),
    takeLatest("UPLOAD_LEAD", authGen.uploadLead),
    takeEvery("GET_TABLE_ROWS", authGen.getTableRows),
    takeEvery("DELETE_ROW_TABLE", authGen.deleteRowTable),
    takeLatest("UPDATE_TABLE_ROW", authGen.updateTableRow),
    takeLatest("LOG_OUT", authGen.logOut),
    takeLatest("GET_TABLE_MODELS", authGen.getTableModels),
    takeLatest("ASSIGN_TABLE", authGen.assignTable),
    takeLatest("TRANSFER_ROWS", authGen.transferRows),
    //
    takeLatest("GET_ROLES", authGen.getRoles),
    takeLatest("UPDATE_ROLE_PERMISSION", authGen.updateRolePermission),
    takeLatest("CREATE_ROLE", authGen.createRole),

    takeLatest("CREATE_USER", authGen.createUser),
    takeLatest("EDIT_USER", authGen.editUser),

    takeLatest("DELETE_USER", authGen.deleteUser),

    takeLatest("EDIT_CATEGORY", authGen.editCategory),
    //
    takeLatest("GET_VICI_DIAL_LISTS", authGen.getViciDialLists),
  ]);
}
