import React, { useState } from "react";
import "./Permissions.scss";
import { Collapse } from "antd";
import { useSelectAll } from "hooks";
import { svg } from "assets";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const { Panel } = Collapse;

const CustomCheckedButton = ({
  selected,
  onChange,
  text,
  className,
  disabled,
  textClassName,
}) => {
  const handleClick = () => {
    onChange && onChange(!selected);
  };

  return (
    <div
      className={"check-container" + className}
      style={disabled ? { filter: "grayscale(1)", pointerEvents: "none" } : {}}
      onClick={handleClick}
    >
      <div className="check-icon">
        <span className={selected ? "active" : ""}>&#10003;</span>
      </div>
      <p className={textClassName}>{text}</p>
    </div>
  );
};
const initialState = {
  name: "",
  phone: "",
  email: "",
  role: "",
  password: "",
  confirm_password: "",
};

const Permissions = () => {
  const location = useLocation().pathname;
  const permissions = useSelector((state) => state.auth.auth.permissions);

  const [values, setValues] = useState(initialState);
  const {
    SelectAll,
    SelectItem,
    selectedAll,
    selectedItems,
    setDefaultValues,
    IsGroupSelected,
    SelectGroup,
  } = useSelectAll({
    data: [],
    initialValues: [],
  });

  const handlePermissionChange = (id) => {
    const type = selectedItems.includes(id);

    SelectItem(id);
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  var NodeIcon = () => {
    React.createElement("img", { src: svg.DownArrow }, "one");
  };

  return (
    <div className="profile">
      {location.match("/settings/roles") && (
        <div className="my-5">
          <label className="fw-bold">Name:</label>
          <input className="mx-3" />
        </div>
      )}

      <div className="permissions">
        <h4>Permissions :</h4>
        <div className="d-flex flex-wrap ">
          {Object.keys(permissions.DATABASE).map((el, index) => (
            <div key={index} className="col-3 pb-4 position-relative">
              <Collapse
                expandIconPosition="left"
                expandIcon={NodeIcon}
                className="d-flex w-100"
              >
                <div className="col-2 d-flex justify-content-center">
                  <CustomCheckedButton selected={true} />
                </div>

                <Panel key={index} header={el} className="col-10" key={el.id}>
                  {permissions.DATABASE[el].map((data, index) => (
                    <p key={index}>{data?.name}</p>
                  ))}
                </Panel>
              </Collapse>
            </div>
          ))}
        </div>
      </div>
      <div className="d-flex justify-content-end" style={{ marginTop: "auto" }}>
        <button
          onChange={handleChange}
          className="done-btn"
          onClick={() => console.log(values)}
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default Permissions;
