import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useSelector } from "react-redux";

export default function PrivateRoute({ ...rest }) {
  // const auth = useSelector((state) => state.auth);
  // useEffect(() => {
  //   if (!auth) {
  //     window.location.replace("/404");
  //   }
  // }, [auth]);
  return <Route {...rest} />;
}
