import { Checkbox, Pagination, Popconfirm } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector, connect } from "react-redux";
import { AuthActions, CommonActions } from "reducers/models";
import { useSelectAll } from "utils";

const defaultPage = "0";
const Table = ({ table, setPopUp, deleteTable, getTableRows, deleteRowTable, currentCategory, ind, transferRows }) => {
  const [editRows, setEditRows] = useState(false);
  const headers = JSON.parse(table.detail || "[]");
  const tableDatas = useSelector((s) => s.auth.tableDatas);
  const [toTake, setTake] = useState(10);
  const [currentPage, setPage] = useState(defaultPage);
  const [tableCollapsed, setCollapsed] = useState(ind === 0 ? true : false);
  const filterData = useSelector((s) => s.common.filterData);

  const { SelectAll, SelectItem, selectedItems } = useSelectAll({
    data: tableDatas?.[table?.id]?.rows || [],
  });

  useEffect(() => {
    console.log(table)
  },[table])

  useEffect(() => {
    getTableRows({ table_id: table.id, start: currentPage * toTake, take: toTake, ...filterData });
  }, [currentPage, toTake]);

  const countPagination = tableDatas?.[table?.id]?.count;
  return (
    <div className="table">
      <div
        className="table--info"
        onClick={() => {
          setCollapsed(true);
        }}
      >
        <i
          onClick={(e) => {
            e.stopPropagation();
            setCollapsed(!tableCollapsed);
          }}
          className={`fal fa-chevron-${tableCollapsed ? "up" : "down"}`}
          aria-hidden="true"
        ></i>
        <span>{table.name.split("_")[table.name.split("_").length - 1]} </span>
        <button
          onClick={() => {
            setEditRows(!editRows);
          }}
        >
          <i className="fa fa-pencil" aria-hidden="true"></i> Edit this table
        </button>
        {table?.model?.id ? (
          <button className="assigned">
            <i className="far fa-check-circle" aria-hidden="true"></i> Table assigned to : {table.model?.name}
          </button>
        ) : (
          <button
            onClick={() => {
              setPopUp({
                type: "assign_table",
                status: true,
                data: { table },
              });
            }}
          >
            <i className="fas fa-plus" aria-hidden="true"></i> Assign table
          </button>
        )}
        {selectedItems.length ? (
          <button
            onClick={() => {
              setPopUp({
                type: "transfer_rows",
                status: true,
                data: { table_id: table.id, ids: selectedItems },
                transferRows,
              });
            }}
          >
            <i className="fa fa-share" aria-hidden="true"></i> Transfer {selectedItems.length} row
          </button>
        ) : (
          false
        )}
        <button
          className="filter"
          onClick={() => {
            setPopUp({
              type: "filter_table",
              status: true,
              data: { table },
              getTableRows,
            });
          }}
        >
          <i className="fal fa-filter" aria-hidden="true"></i>{" "}
        </button>
      </div>
      {tableCollapsed && (
        <>
          <div className={"table--header" + (editRows ? " editing" : "")}>
            <div className="edit">
              <i
                onClick={() => {
                  setPopUp({
                    type: "edit_table",
                    status: true,
                    data: table,
                    currentCategory,
                  });
                }}
                className="fa fa-pencil"
                aria-hidden="true"
              ></i>
            </div>
            {table?.model?.id && (
              <div
                style={{
                  width: "30px",
                  flexShrink: 0,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    SelectAll();
                  }}
                />
              </div>
            )}

            {Array.isArray(headers || []) &&
              (headers || []).map((h, i) => (
                <div key={i}>
                  {h.name.replaceAll("_", " ")} ({h.type})
                </div>
              ))}
          </div>
          {(tableDatas?.[table?.id]?.rows || []).map((row) => {
            return (
              <div key={row.id} className={"table--row" + (editRows ? " editing" : "")}>
                <div className="edit">
                  <i
                    onClick={() => {
                      deleteRowTable({
                        table_id: table.id,
                        ids: [row.id],
                      });
                    }}
                    className="fal fa-times"
                    aria-hidden="true"
                  ></i>
                  <i
                    className="fal fa-pen-alt"
                    onClick={() => {
                      setPopUp({
                        type: "edit_table_row",
                        status: true,
                        data: { ...row, headers, table_id: table.id },
                      });
                    }}
                  ></i>
                </div>
                {table?.model?.id && (
                  <div
                    style={{
                      width: "30px",
                      flexShrink: 0,
                    }}
                  >
                    <Checkbox
                      checked={selectedItems.includes(row.id)}
                      onChange={() => {
                        SelectItem(row.id);
                      }}
                    />
                  </div>
                )}

                {headers.map((h, i) => (
                  <div
                    style={{
                      width: `calc(100% / ${(headers || []).length})`,
                    }}
                    key={i}
                  >
                    {row[h.name]}
                  </div>
                ))}
              </div>
            );
          })}
          <div className="table--actions">
            <Popconfirm
              title="Are you sure to delete this table?"
              onConfirm={(e) => {
                deleteTable({ name: table.name.split("db_file_")[1], category_id: currentCategory.id });
              }}
              okText="Delete"
              cancelText="Cancel"
            >
              <button>
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </Popconfirm>
            <button
              onClick={() => {
                setPopUp({
                  type: "upload_excel",
                  status: true,
                  data: table,
                });
              }}
            >
              <i className="fa fa-upload" aria-hidden="true"></i>
            </button>
          </div>
          <div className="table--pagination">
            <Pagination
              total={countPagination}
              pageSizeOptions={[10, 30, 50, 100]}
              showQuickJumper
              showSizeChanger
              pageSize={toTake}
              current={+currentPage + 1}
              onShowSizeChange={(e, b) => {
                setTake(b);
              }}
              onChange={(e) => {
                setPage(e - 1);
              }}
              className="pagination-custom"
            />
          </div>
        </>
      )}
    </div>
  );
};
export default connect(null, { ...CommonActions, ...AuthActions })(Table);
