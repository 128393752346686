import request from "../utils/requests";
import { notification } from "antd";

export const fetchMain = (e) => request.get(`/api/main`, {});

export const fetchCategories = (e) => request.get(`/api/categories`).catch((error) => ({ error }));

export const createCategory = (data) =>
  request
    .put(`/api/category`, {
      ...data,
    })
    .catch((error) => ({ error }));

export const editUser = (data) =>
  request
    .put(`/api/user/${data.id}`, {
      ...data,
    })
    .catch((error) => ({ error }));

export const getUsers = (data) => {
  return request
    .get(`/api/role/users`, {
      params: {
        ...data,
      },
    })
    .catch((error) => ({ error }));
};

export const deleteCatgory = (data) => {
  return request
    .delete(`/api/category/${data.id}`, {
      params: {
        id: data.id,
      },
    })
    .catch((error) => ({ error }));
};

export const createTable = (data) =>
  request
    .post(`/api/table/create`, {
      ...data,
    })
    .catch((error) => ({ error }));

export const addColumns = (data) =>
  request
    .put(`/api/table/add/column`, {
      ...data,
    })
    .catch((error) => ({ error }));

export const editColumns = (data) =>
  request
    .put(`/api/table/edit/column`, {
      ...data,
    })
    .catch((error) => ({ error }));

export const getTables = (data) => {
  return request
    .get(`/api/tables`, {
      params: {
        ...data,
      },
    })
    .catch((error) => ({ error }));
};

export const deleteTable = (data) => {
  return request
    .delete(`/api/table/delete`, {
      params: {
        ...data,
      },
    })
    .catch((error) => ({ error }));
};

export const uploadLead = (data) => {
  console.log(data)
  var bodyFormData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    bodyFormData.append(key, value);
  });

  const config = {
    onUploadProgress: function (progressEvent) {
      console.log("progressEvent", progressEvent);
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      // window.store.dispatch({
      //   type: "SET_PROGRESS_LOAD",
      //   progressLoad: percentCompleted,
      // });
      notification["info"]({
        key: "loading",
        message: `Uploading file...`,
        icon: <i className="fal fa-sync fa-rotate"></i>,
        duration: 0,
      });
      if (percentCompleted === 100) {
        notification["success"]({
          key: "loading",
          message: `File uploaded`,
          icon: <i className="fal fa-check"></i>,
          duration: 0,
        });
      }
      console.log("percentCompleted", percentCompleted);
    },
  };

  return request.post(`/api/import/file`, bodyFormData, config).catch((error) => ({ error }));
};

export const getTableRows = (params) => {
  return request
    .get(`/api/table/details`, {
      params,
    })
    .catch((error) => ({ error }));
};

export const deleteRowTable = (data) => {
  return request
    .delete(`/api/table/detail/delete`, {
      params: {
        ...data,
      },
    })
    .catch((error) => ({ error }));
};

export const editCategory = (data) => {
  return request
    .put(`/api/category/update`, {
      ...data,
    })
    .catch((error) => ({ error }));
};
export const updateTableRow = (data) => {
  return request
    .put(`/api/table/detail/edit`, {
      ...data,
    })
    .catch((error) => ({ error }));
};

export const logOut = (data) => {
  return request
    .post(`/api/logout`, {
      ...data,
    })
    .catch((error) => ({ error }));
};

export const getTableModels = () => {
  return request.get(`/api/table/actions`).catch((error) => ({ error }));
};
export const getViciDialLists = () => {
  return request.get(`/api/vicidial/lead/lists`).catch((error) => ({ error }));
};
export const assignTable = (data) =>
  request
    .put(`/api/table/assign`, {
      ...data,
    })
    .catch((error) => ({ error }));

export const transferRows = (data) => {
  return request.post(`/api/table/select/from`, data).catch((error) => ({ error }));
};
export const getRoles = () => {
  return request.get(`/api/roles`).catch((error) => ({ error }));
};
export const updateRolePermission = (role_id, permission_id, action) => {
  return request
    .post(`/api/role/permissions`, {
      role_id,
      permission_id: permission_id.toString(),
      action,
    })
    .catch((error) => ({ error }));
};

export const createRole = (data) => {
  return request.post(`/api/role`, data).catch((error) => ({ error }));
};

export const createUser = (data) => {
  return request.post(`/api/register`, data).catch((error) => ({ error }));
};
export const deleteUser = (data) => {
  return request.delete(`/api/user/${data.id}`).catch((error) => ({ error }));
};
