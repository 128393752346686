import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AuthActions } from "reducers/models";

const TopMenuRight = ({ logOut, history }) => {
  return (
    <div className="top-menu database">
      <i
        onClick={() => {
          window.location.hash = "home";
        }}
        className="fal fa-home"
        aria-hidden="true"
      ></i>

      <button
        onClick={() => {
          if (window.location.hash.includes("/roles")) {
            window.location.hash = "/database";
          } else {
            window.location.hash = "/roles";
          }
        }}
      >
        {window.location.hash.includes("/roles") ? (
          <>
            <i className="fal fa-database" aria-hidden="true"></i> Database Page
          </>
        ) : (
          <>
            <i className="fal fa-users" aria-hidden="true"></i> Roles Page
          </>
        )}
      </button>
      <div className="right">
        <div className="inpWrapp">
          <i className="fal fa-search" aria-hidden="true"></i>
          <input type="text" disabled />
        </div>
        <i
          onClick={() => {
            logOut();
          }}
          className="fal fa-sign-out"
          aria-hidden="true"
        ></i>
      </div>
    </div>
  );
};
export default withRouter(connect(null, AuthActions)(TopMenuRight));
