import { Popconfirm, Tooltip } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { AuthActions, CommonActions } from "reducers/models";

const Users = ({ activeRoleId }) => {
  const dispatch = useDispatch();
  const users = useSelector((s) => s.auth.users);
  useEffect(() => {
    dispatch(AuthActions.getUsers({ role_id: activeRoleId }));
  }, [activeRoleId]);
  return (
    <div>
      <div className="table">
        <div className="table--header">
          <div>Name</div>
          <div>Last Name</div>
          <div>Email</div>
          <div className="actions"></div>
        </div>
        {users.map((u) => {
          return (
            <div key={u.id} className="table--row">
              <div>{u.first_name}</div>
              <div>{u.last_name}</div>
              <div>{u.email}</div>
              <div className="actions">
                <i
                  onClick={() => {
                    dispatch(
                      CommonActions.setPopUp({
                        status: true,
                        type: "edit_user",
                        data: { role_id: activeRoleId, user: u },
                      }),
                    );
                  }}
                  className="fal fa-pencil"
                  aria-hidden="true"
                ></i>
                <Tooltip title={window.atob(u.detail?.real_password)}>
                  <i className="fal fa-eye mx-1" aria-hidden="true"></i>
                </Tooltip>
                <Popconfirm
                  okText="Yes"
                  cancelText="No"
                  title="Are you sure to delete this user?"
                  onConfirm={() => {
                    dispatch(AuthActions.deleteUser({ id: u.id }));
                  }}
                >
                  <i className="fal fa-trash" aria-hidden="true"></i>
                </Popconfirm>
              </div>
            </div>
          );
        })}
        <div className="table--actions">
          <Tooltip title="Add user">
            <button
              onClick={() => {
                dispatch(
                  CommonActions.setPopUp({ status: true, type: "create_user", data: { role_id: activeRoleId } }),
                );
              }}
            >
              <i className="fal fa-plus" aria-hidden="true"></i>
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
export default Users;
