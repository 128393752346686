import React from "react";
import "./App.scss";
import Root from "./Root";
import { Provider } from "react-redux";
import { store } from "reducers/store";
import "antd/dist/antd.css";
function App() {
  return (
    <>
      <Provider store={store}>
        <Root />
      </Provider>
    </>
  );
}

export default App;
