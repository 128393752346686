import { Select } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";

const TransferRows = ({ transferRows, data }) => {
  const { lead_lists, lead_statuses } = useSelector((s) => s.auth.auth);
  const [formData, setData] = useState({});
  return (
    <>
      <div className="filterTable">
        <div className="header">Transfer rows</div>
        <div>
          <div className="label">Status</div>
          <Select
            onChange={(e) => {
              setData({ ...formData, status_id: e });
            }}
          >
            {lead_statuses.map((item) => {
              return (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              );
            })}
          </Select>
        </div>
        <div>
          <div className="label">List</div>
          <Select
            onChange={(e) => {
              setData({ ...formData, list_id: e });
            }}
          >
            {lead_lists.map((item) => {
              return (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              );
            })}
          </Select>
        </div>
      </div>
      <button
        onClick={() => {
          transferRows({ ...data, ...formData });
        }}
      >
        Submit
      </button>
    </>
  );
};

export default TransferRows;
