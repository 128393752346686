const isLocalHost = window.location.href.match(/localhost|192/g);
export let endpoint = `${isLocalHost ? "http:" : window.location.protocol}//${
  isLocalHost ? "database.stage" : window.location.host
}${isLocalHost ? "/backend" : "/backend"}`;

window.apiUrl = endpoint;

const data = {
  endpoint,
  baseUrl: endpoint,
  headers: {},
};

export default data;
