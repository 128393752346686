import { Input } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AuthActions } from "reducers/models";
import { b64toBlob } from "utils";
const EditCategory = ({ data }) => {
  const dispatch = useDispatch();

  console.log("dataaa", data);
  const [formData, setFormData] = useState({});
  useEffect(() => {
    if (!formData.name) setFormData(data);
  }, [data]);
  return (
    <>
      <div>
        <div className="label">Name</div>
        <Input value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
      </div>
      <div>
        <div className="label">Image</div>
        <label htmlFor="upl">
          {formData.imageObj ? (
            <img src={formData?.imageObj?.url} alt="" />
          ) : (
            <i className="fal fa-upload" aria-hidden="true"></i>
          )}
        </label>
        <input
          type="file"
          id="upl"
          onChange={(e) => {
            const files = e.target.files;
            for (const file of files) {
              const reader = new FileReader();
              reader.onload = function (e) {
                const b64 = e.target.result;
                const url = b64toBlob(b64, file.type)[0];
                const blobi = b64toBlob(b64, file.type)[1];
                setFormData({
                  ...formData,
                  imageObj: {
                    file: file,
                    id: `${file.name}${file.size}`,
                    name: file.name,
                    url,
                    blobi,
                    type: file.type,
                  },
                  image: b64.split(",")[1],
                });
              };
              reader.readAsDataURL(file);
            }
          }}
        />
      </div>
      <button
        onClick={() => {
          let paramsToSend = { id: data.id, ...formData };
          Object.entries(paramsToSend).forEach(([key, val]) => {
            if (key !== "id" && (data[key] === val || val?.includes?.("/storage"))) {
              delete paramsToSend[key];
            }
          });
          dispatch(AuthActions.editCategory(paramsToSend));
        }}
      >
        Save
      </button>
    </>
  );
};
export default EditCategory;
