import { useEffect } from "react";
import "./Layouts.scss";
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const CustomHeader = ({ handleClick, value }) => {
  const categories = useSelector((state) => state.auth.categories);
  // console.log(categories);
  const dataRoles = [{ id: 1, name: "create role", user: "user of" }];
  const dataUsers = [
    { id: 1, name: "role 1", user: "user of" },
    { id: 2, name: "role 2", user: "user of" },
    { id: 3, name: "role 3", user: "user of" },
  ];
  const dataCategories = [{ id: 1, name: "create" }, ...categories];
  // console.log(dataCategories);

  const location = useLocation().pathname;

  return (
    <>
      <div className="lay_3-content d-flex justify-content-around">
        {dataRoles.map((el, index) => (
          <div key={index} className="">
            <NavLink
              className="text-header"
              to={`/settings/roles/${el.name}`}
              activeClassName="active"
            >
              {location.match("/settings/roles") && (
                <h3 className="py-2 fw-bold text-capitalize">{el.name}</h3>
              )}
            </NavLink>
          </div>
        ))}
      </div>
      <div className="lay_3-content d-flex justify-content-around">
        {dataUsers.map((el, index) => (
          <div key={index} className="">
            <NavLink
              className="text-header"
              to={`/settings/users/${el.name}`}
              activeClassName="active"
            >
              {location.match("/settings/users") && (
                <h3 className="py-2 fw-bold text-capitalize">
                  {el.user} {el.name}
                </h3>
              )}
            </NavLink>
          </div>
        ))}
      </div>
      <div className="lay_3-content d-flex justify-content-around">
        {dataCategories.map((el, index) => (
          <div key={index} className="">
            <NavLink
              className="text-header"
              to={`/settings/categories/${el.name}`}
              activeClassName="active"
            >
              {location.match("/settings/categories") && (
                <h3
                  className="py-2 fw-bold text-capitalize"
                  onClick={handleClick}
                  value={value}
                  id={value}
                >
                  {el.name}
                </h3>
              )}
            </NavLink>
          </div>
        ))}
      </div>
    </>
  );
};

const LayoutsTest = ({
  options,
  children,
  activePage,
  setActivePage,
  match,
  withParams,
  withId,
  tabChangeHandler,
}) => {
  useEffect(() => {
    if (withId && !match?.params.indRoute && withParams) {
      window.location.hash = `${window.location.hash}${options[0]?.content}/${withId}`;
      return;
    }
    if (!match?.params.indRoute && withParams) {
      window.location.hash = `${window.location.hash}/${options[0]?.content}`;
    }
  }, []);
  return (
    <>
      {options &&
        options.map((el, index) => (
          <div key={index + el.content}>
            <CustomHeader
              value={(el.content || "").split("_").join(" ")}
              activePage={activePage}
              handleClick={() => {
                if (withId) {
                  window.location.hash = `${match.path.split(":")[0]}${
                    el.content
                  }/${withId}`;
                  setActivePage(el.content);
                } else if (withParams) {
                  window.location.hash = `${match.path.split(":")[0]}${
                    el.content
                  }`;
                  setActivePage(el.content);
                } else {
                  window.location.hash = `${match.path.split(":")[0]}${+index}`;
                  setActivePage(index);
                }
                tabChangeHandler?.(el);
              }}
              className={
                el.className +
                (activePage === (withParams ? el.content : index)
                  ? " active-btn"
                  : "")
              }
            />
          </div>
        ))}
      <div className="layout1-content">
        {children && children.length > 1 ? (
          children.map(
            //tabCheck prop to conditionally render tabs (tabCheck === contentName of option)
            (child, index) =>
              activePage === (withParams ? child.props.tabCheck : index) &&
              child
          )
        ) : (
          <>{children}</>
        )}
      </div>
    </>
  );
};

export default withRouter(LayoutsTest);
