import React from "react";
import { connect } from "react-redux";

import Router from "./navigation";
import { messages as enMessages } from "./locales/en/messages.js";
import { messages as itMessages } from "./locales/it/messages.js";
import { I18nProvider } from "@lingui/react";
import { i18n } from "@lingui/core";

const messages = {
  en: enMessages,
  it: itMessages,
};

class Root extends React.Component {
  updateDimensions = () => {
    this.props.takeScreenWidth(window.innerWidth);
  };

  render() {
    const { screenWidth } = this.props;
    return (
      <div className="App">
        <Router mobileCheck={this.props.mobileCheck} screenWidth={screenWidth} />
      </div>
    );
  }
}

const mapsStateToProps = (state) => ({});

export default connect(mapsStateToProps, {})(Root);
