import { useState } from "react";

/**
 * @name b64toBlob
 * @description Function that converts base64 into a blob or url.
 * @param {string} b64Data b64 string splited (without ex: image/png || application/pdf)
 * @param {string} contentType content type ex: image/png , application/pdf
 * @returns {array} return array [0,1] first value is the url string and second is blob file
 */

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = b64Data.includes(",") ? window.atob(b64Data.split(",")[1]) : window.atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return [URL.createObjectURL(blob), blob];
};

export const useSelectAll = ({ data }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);

  const SelectAll = () => {
    if (!selectedAll) {
      setSelectedAll(true);
      let ids = [];

      if (!Array.isArray(data))
        for (const key in data) {
          ids = [...ids, ...data[key].map((el) => el.id)];
        }
      else ids = data.map((el) => el.id);
      setSelectedItems(ids);
    } else {
      setSelectedItems([]);
      setSelectedAll(false);
    }
  };

  const SelectGroup = (permissions) => {
    let ids = permissions?.map((el) => el.id);
    let newSelectedItems = selectedItems;
    for (let i = 0; i < ids?.length; i++) {
      if (!selectedItems.includes(ids[i])) {
        newSelectedItems = [...newSelectedItems, ids[i]];
      } else newSelectedItems = newSelectedItems.filter((item) => item !== ids[i]);
    }
    setSelectedItems(newSelectedItems);
  };

  const IsGroupSelected = (ids) => {
    for (let i = 0; i < ids?.length; i++) {
      if (!selectedItems.includes(ids[i])) {
        return false;
      }
    }
    if (!ids) return false;
    return true;
  };

  const setDefaultValues = (data) => {
    setSelectedItems(data);
  };

  const SelectItem = (id) => {
    if (!selectedItems.includes(id)) setSelectedItems([...selectedItems, id]);
    else {
      const arr = selectedItems.filter((item) => item !== id);
      setSelectedItems(arr);
    }
  };

  return {
    SelectAll,
    SelectItem,
    selectedAll,
    selectedItems,
    setDefaultValues,
    SelectGroup,
    IsGroupSelected,
  };
};
